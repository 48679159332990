import React from 'react';

function ProjectNotification() {







  
  return <div>project Notification Screen</div>;
}

export default ProjectNotification;
